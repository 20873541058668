<template>
  <!-- Dashboard / Stats / Members. / Scores / Account & subscription => only account tab -->
  <div class="main-side-menu">
    <Menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed && !isSm"
    >
      <div v-if="isSm" class="bg-bg text-text flex items-center justify-between px-3 py-3">
        <img src="@/assets/royal-logo.png" alt="Royal logo" class="h-10 w-" />

        <span
          class="text-lg cursor-pointer opacity-70 hover:opacity-100"
          @click="this.closeSideDrawer"
        >
          <PhX weight="bold" />
        </span>
      </div>
      <menu-item key="/dashboard" @click="() => goTo('/dashboard')">
        <template #icon>
          <PhHouse weight="duotone" size="18" />
        </template>
        <span class="inline-block w-36">Dashboard</span>
      </menu-item>

      <sub-menu :key="subMenuKeys[2]">
        <template #icon>
          <PhUsers weight="duotone" size="18" />
        </template>
        <template #title>Users</template>
        <menu-item key="/dashboard/admins" @click="() => goTo('/dashboard/admins')"
          >Admins</menu-item
        >
         <menu-item key="/dashboard/positions" @click="() => goTo('/dashboard/positions')"
          >Positions</menu-item
        >
        <menu-item key="/dashboard/users" @click="() => goTo('/dashboard/users')"
          >Users</menu-item
        >

        <menu-item key="/dashboard/roles" @click="() => goTo('/dashboard/roles')">Roles</menu-item>
      </sub-menu>

      <sub-menu :key="subMenuKeys[0]">
        <template #icon>
          <PhGear weight="duotone" size="18" />
        </template>
        <template #title>Customer List</template>

        <menu-item key="/dashboard/accounts" @click="() => goTo('/dashboard/accounts')"
          >Accounts</menu-item
        >

        <menu-item key="/dashboard/doctors" @click="() => goTo('/dashboard/doctors')"
          >Doctors</menu-item
        >

        <menu-item key="/dashboard/pharmacy" @click="() => goTo('/dashboard/pharmacy')"
          >Pharmacy List</menu-item
        >
      </sub-menu>

      <sub-menu :key="subMenuKeys[3]">
        <template #icon>
          <PhPackage weight="duotone" size="18" />
        </template>
        <template #title>Products</template>

        <menu-item key="/dashboard/products" @click="() => goTo('/dashboard/products')"
          >Products</menu-item
        >

        <menu-item key="/dashboard/company" @click="() => goTo('/dashboard/company')"
          >Company</menu-item
        >

        <menu-item key="/dashboard/category" @click="() => goTo('/dashboard/category')"
          >Category</menu-item
        >
      </sub-menu>

      <!-- <menu-item key="/dashboard/products" @click="() => goTo('/dashboard/products')">
        <template #icon>
          <PhPackage weight="duotone" size="18" />
        </template>
        <span>Products</span>
      </menu-item> -->

      <sub-menu :key="subMenuKeys[1]">
        <template #icon>
          <PhTarget weight="duotone" size="18" />
        </template>
        <template #title>Reports</template>

        <menu-item key="/dashboard/all_plans" @click="() => goTo('/dashboard/all_plans')"
          >Plans</menu-item
        >

        <menu-item key="/dashboard/current_visits" @click="() => goTo('/dashboard/current_visits')"
          >Current Visits</menu-item
        >

        <menu-item key="/dashboard/visits" @click="() => goTo('/dashboard/visits')"
          >Visits</menu-item
        >

        <menu-item key="/dashboard/maps" @click="() => goTo('/dashboard/maps')">Map</menu-item>
      </sub-menu>

      <menu-item key="/dashboard/notifications" @click="() => goTo('/dashboard/notifications')">
        <template #icon>
          <PhNotification weight="duotone" size="18" />
        </template>
        <span>Notifications</span>
      </menu-item>

      <menu-item key="/dashboard/logs" @click="() => goTo('/dashboard/logs')">
        <template #icon>
          <PhActivity weight="duotone" size="18" />
        </template>
        <span>Logs</span>
      </menu-item>
      <menu-item key="/dashboard/setting" @click="() => goTo('/dashboard/setting')">
        <template #icon>
          <PhActivity weight="duotone" size="18" />
        </template>
        <span>Settings</span>
      </menu-item>

    </Menu>
  </div>
</template>

<script>
import { Menu, MenuItem, SubMenu } from 'ant-design-vue';
import {
  PhHouse,
  PhX,
  // PhChartBar,
  PhUsers,
  PhTarget,
  PhNotification,
  PhActivity,
  PhPackage,
  PhGear,
  // PhCreditCard,
  // PhChatCenteredDots,
  // PhCalculator,
} from 'phosphor-vue';
import { mapMutations } from 'vuex';
import { LAYOUT } from '../../../store/types';

export default {
  components: {
    Menu,
    SubMenu,
    MenuItem,

    PhHouse,
    PhX,
    PhActivity,
    // PhChartBar,
    PhUsers,
    PhTarget,
    PhNotification,
    PhPackage,
    PhGear,
    // PhCreditCard,
    // PhChatCenteredDots,
    // PhCalculator,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    isSm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedKeys: [],
      openKeys: [],
      // preOpenKeys: ['sub1'],
      subMenuKeys: [
        '/dashboard/accounts,/dashboard/doctors,/dashboard/pharmacy',
        '/dashboard/acc_type,/dashboard/specialty,/dashboard/classes,/dashboard/bricks',
        '/dashboard/all_plans,/dashboard/visits,/dashboard/maps,/dashboard/current_visits',
        '/dashboard/admins,/dashboard/users,/dashboard/roles,/dashboard/positions',
        '/dashboard/products,/dashboard/company,/dashboard/category',
      ],
    };
  },

  mounted() {
    const pathname = this.$route.path;
    this.selectedKeys = [pathname];
    this.setOpenKeys(pathname);
  },
  watch: {
    $route: {
      handler(val) {
        if (val && val.path) {
          this.selectedKeys = [val.path];
          this.setOpenKeys(val.path);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations([LAYOUT.SET_TOGGOLE_SIDEMENU]),
    setOpenKeys(val) {
      const openKeys = [];
      this.subMenuKeys.forEach((item) => {
        const subItems = item.split(',') || [];
        if (subItems.includes(val)) {
          openKeys.push(item);
        }
      });
      this.openKeys = openKeys;
    },
    goTo(url) {
      this.$router.push(url);
      this.SET_TOGGOLE_SIDEMENU(false);
    },
    goToSubTab(urls) {
      this.$router.push(urls.split(',')[0]);
      this.SET_TOGGOLE_SIDEMENU(false);
    },
    closeSideDrawer() {
      this.SET_TOGGOLE_SIDEMENU(false);
    },
  },
};
</script>
